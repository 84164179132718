import { Vue, Component } from "vue-property-decorator";
import { settings } from "@/settings";

@Component
export default class Login extends Vue {
    public isLoggingIn = false;

    public get appName(): string {
        return settings.appName;
    }

    login(): void {
        this.isLoggingIn = true;
        this.$store.dispatch("account/login", true).then(() => {
            this.$router.replace({ name: "Home" });
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);

            this.$emit("error", "An error occurred when loading your Microsoft account.");
        }).finally(() => {
            this.isLoggingIn = false;
        });
    }
}
