
import { Component, Vue } from "vue-property-decorator";

@Component
export default class NavBar extends Vue {
    public menuItems: any[];

    public sidebar = false;

    constructor() {
        super();
        this.menuItems = [
            { title: "Home", path: "/Home", icon: "mdi-home" },
            { title: "Formations", path: "/WhatToDo", icon: "face" },
            { title: "Programmes Semaines", path: "/Formation", icon: "mdi-calendar" },
        ];
    }

    async mounted(): Promise<void> {
        await this.$store.dispatch("account/loadUserPhoto");
    }

    get userName(): string {
        return this.$store.getters["account/userName"];
    }

    logout(): void {
        this.$store
            .dispatch("account/logout")
            .then(() => {
                this.$router.replace({ name: "Login" });
            })
            .catch((error) => {
                this.$emit(
                    "error",
                    "An error occurred when loading your Microsoft account.",
                );
            })
            .finally(() => {});
    }
}
