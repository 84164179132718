import { AxiosError } from "axios";
import { ConflictError, ForbiddenError, NotFoundError, UnauthorizedError, UnexpectedError } from "@/errors";

class ErrorService {
    public handleError(error: AxiosError): Error {
        switch (error?.response?.status) {
            case 401:
                throw new UnauthorizedError();
            case 403:
                throw new ForbiddenError();
            case 404:
                throw new NotFoundError();
            case 409:
                throw new ConflictError(error?.response?.data?.detail);
            default:
                throw new UnexpectedError();
        }
    }
}

const errorService = new ErrorService();

export {
    errorService,
};
