import { NavigationGuard } from "vue-router";
import store from "@/store";

export const authGuard: NavigationGuard = async (to, from, next) => {
    await store.dispatch("account/login", false);

    const isAuthenticated = store.getters["account/isAuthenticated"];

    if (!isAuthenticated && to.meta && to.meta.requiresAuth) {
        next({ name: "Login" });
    }
    else if (isAuthenticated && to.meta && !to.meta.requiresAuth) {
        next({ name: "Home" });
    }
    else {
        next();
    }
};
