
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ArbreDecision extends Vue {

    get getBu(): string {
        return this.$store.state.account.bu;
    }
}
