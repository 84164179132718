import { GetterTree } from "vuex";
import { IAccountState } from "./state";
import { IUser } from "@/models";

const getters: GetterTree<IAccountState, any> = {
    isAuthenticated: (state): boolean => !(!state.msalAccount),
    user: (state): IUser | null => state.user,
    userEmail: (state): string => state.msalAccount ? state.msalAccount.username : "",
    userGroups: (state): string[] => state.groups,
    userName: (state): string => state.msalAccount && state.msalAccount.name ? state.msalAccount.name : "",
    userPhoto: (state): string => state.userPhoto ? state.userPhoto : "",
};

export { getters };
