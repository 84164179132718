
import { Component, Prop, Vue } from "vue-property-decorator";
import buConstantes from "../models/buConstantes";


@Component
export default class ToggleButton extends Vue {

    public setValue(ev): void {
        this.$store.state.account.bu = ev;
    }

    mounted() {
        if (this.$store.state.account.bu == buConstantes.AppBU) {
            this.$nextTick(() => {
                document.getElementById("appBu").click();
            });
        }
    }

}
