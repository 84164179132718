
import { Component, Prop, Vue } from "vue-property-decorator";
import FileDrop from "@/components/FileDrop.vue";
import Console from "@/components/Console.vue";
import { apiService } from "@/services";
import { settings } from "@/settings";

@Component({
    components: {
        FileDrop,
        Console,
    },
})
export default class Export extends Vue {
    public action;

    public valid: boolean;

    public fileValidationErrors: string[];

    public errors: string[];

    public uploadedFile: File;

    constructor() {
        super();

        this.action = null;
        this.valid = false;
        this.fileValidationErrors = [];
        this.errors = [];
        this.uploadedFile = null;
    }

    public fileDroped($file: File): void {
        this.uploadedFile = $file;
        this.fileValidationErrors = [];
    }

    public get declarationPath(): string {
        return settings.webApi.dependencyPath;
    }

    public get delegationPath(): string {
        return settings.webApi.delegationPath;
    }

    public validate(): void {
        let validated = (
            this.$refs.form as Vue & { validate: () => boolean }
        ).validate();
        this.fileValidationErrors = [];
        if (this.uploadedFile === null) {
            this.fileValidationErrors.push("Ce champs est obligatoire !");
            validated = false;
        }
        else {
            if (this.uploadedFile.type !== "application/vnd.ms-excel") {
                this.fileValidationErrors.push(
                    "Veuillez fournir un fichier csv valide !",
                );
                validated = false;
            }
        }
        if (validated) {
            const formData = new FormData();
            formData.append("file", this.uploadedFile);
            const apiClient = apiService.getApiClient();
            this.$notify({ type: "info", text: "Votre fichier a été envoyé" });
            apiClient
                .post(`${settings.webApi.baseUrl}${this.action}`, formData)
                .then((res) => {
                    this.$notify({
                        type: "success",
                        text: res.data,
                        duration: 10000,
                    });
                })
                .catch((err) => {
                    this.errors = err.response.data;
                })
                .finally(() => {
                    this.reset();
                });
        }
    }

    public reset(): void {
        (this.$refs.form as Vue & { reset: () => boolean }).reset();
    }
}
