import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#f78036",
                secondary: "#f78036",
            },
        },
        options: {
            customProperties: true,
        },
    },
});

export default vuetify;
