import { Vue, Component } from "vue-property-decorator";
import { NavBar, ToggleButton } from "./components";

@Component({
    components:{
        NavBar,
        ToggleButton
    },
})
export default class App extends Vue {
    onSuccessNotification(messages: string | string[]): void {
        if (!Array.isArray(messages)) {
            messages = [messages];
        }

        messages.forEach(message => {
            this.$notify({ type: "success", duration: 5000, text: message });
        });
    }

    onErrorNotification(messages: string | string[]): void {
        if (!Array.isArray(messages)) {
            messages = [messages];
        }

        messages.forEach(message => {
            this.$notify({ type: "error", duration: 5000, text: message });
        });
    }

    get isAuthenticated(): boolean {
        return this.$store.getters["account/isAuthenticated"];
    }
}
