
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Console extends Vue {

    @Prop()
    errors: string[];

}
