import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import {
    Formation,
    Home,
    Login,
    WhatToDo,
} from "@/views";
import { authGuard } from "@/router/AuthGuard";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/whattodo",
        name: "WhatToDo",
        component: WhatToDo,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/formation",
        name: "Formation",
        component: Formation,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/auth",
        name: "Login",
        component: Login,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(authGuard);

export default router;
