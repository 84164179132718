import * as Msal from "@azure/msal-browser";
import { settings } from "@/settings";

class AuthService {
    private readonly msalConfig: Msal.Configuration;

    private readonly msalInstance: Msal.PublicClientApplication;

    public constructor() {
        this.msalConfig = {
            auth: {
                clientId: settings.azureAd.clientId,
                authority: `${settings.azureAd.instance}/${settings.azureAd.tenantId}`,
                redirectUri: `${window.location.origin}${settings.azureAd.callbackPath}`,
                navigateToLoginRequestUrl: true,
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true,
            },
        };
        this.msalInstance = new Msal.PublicClientApplication(this.msalConfig);
    }

    public async getAccessToken(tryPopupLogin: boolean): Promise<string | null> {
        const account = this.getAccount();

        if (account) {
            return this.silentLogin(tryPopupLogin);
        }
        else if (!account && tryPopupLogin) {
            return this.popupLogin();
        }
        else {
            return null;
        }
    }

    public async getGraphToken(): Promise<string | null> {
        const account = this.getAccount();

        if (account) {
            return this.silentGraphLogin();
        }

        return null;
    }

    public async silentLogin(tryPopupLogin: boolean): Promise<string | null> {
        const request = {
            account: this.getAccount() as Msal.AccountInfo,
            scopes: [],
        };

        try {
            const tokenResponse = await this.msalInstance.acquireTokenSilent(request);
            return tokenResponse.accessToken;
        }
        catch (silentError) {
            const isHandledError =
                silentError instanceof Msal.InteractionRequiredAuthError
                || silentError instanceof Msal.BrowserAuthError;

            if (isHandledError && tryPopupLogin) {
                return await this.popupLogin();
            }
        }

        return null;
    }

    public async silentGraphLogin(): Promise<string> {
        const request = {
            account: this.getAccount() as Msal.AccountInfo,
            scopes: this.getGraphScopes(),
        };

        const tokenResponse = await this.msalInstance.acquireTokenSilent(request);
        return tokenResponse.accessToken;
    }

    public async popupLogin(): Promise<string> {
        const request = {
            scopes: [],
        };

        const tokenResponse = await this.msalInstance.acquireTokenPopup(request);
        return tokenResponse.accessToken;
    }

    public async logout(): Promise<void> {
        if (this.msalInstance) {
            return await this.msalInstance.logoutRedirect({
                postLogoutRedirectUri: `${window.location.origin}${settings.azureAd.callbackPath}`,
            });
        }
    }

    public getAccount(): Msal.AccountInfo | null {
        if (this.msalInstance) {
            return (
                this.msalInstance.getActiveAccount() ??
                this.msalInstance.getAllAccounts()[0]
            );
        }

        return null;
    }

    private getScopes(): string[] {
        const stringScopes = settings.azureAd.scopes;
        const scopes = stringScopes.split(";");
        return scopes;
        // return scopes.map((scope: string) => `api://${this.msalConfig.auth.clientId}/${scope}`);
    }

    private getGraphScopes(): string[] {
        // const stringScopes = settings.graphApi.scopes;

        // return stringScopes.split(";");
        return ["https://graph.microsoft.com/User.Read"];
    }
}

const authService = new AuthService();

export { authService };
