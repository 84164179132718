import { ActionTree } from "vuex";
import { LOGIN, LOGOUT, SET_GROUPS, SET_PHOTO, SET_USER } from "./mutations-types";
import { IAccountState } from "./state";
import { authService, graphService } from "@/services";

export const actions: ActionTree<IAccountState, any> = {

    loadUserPhoto: async (context): Promise<void> => {
        const photo = await graphService.getCurrentUserPhoto();
        context.commit(SET_PHOTO, photo);
    },

    loadUserAdGroups: async (context): Promise<void> => {
        const uniqueId = context.state.msalAccount?.localAccountId ?? "";
        const groups = await graphService.getUserGroups(uniqueId);
        context.commit(SET_GROUPS, groups);
    },

    login: async (context, tryPopupLogin: boolean): Promise<void> => {
        const token = await authService.getAccessToken(tryPopupLogin);
        if (token !== null) {
            context.commit(LOGIN, authService.getAccount());
        }

        return;
    },

    logout: async (context): Promise<void> => {
        await authService.logout();
        context.commit(LOGOUT);
    },
};
