import * as Msal from "@azure/msal-browser";
import { authService } from "@/services";
import { IUser } from "@/models";

interface IAccountState {
    groups: string[];
    msalAccount: Msal.AccountInfo | null;
    user: IUser | null;
    userPhoto: string | null;
    bu: string | null;
}

const initialState: IAccountState = {
    groups: [],
    msalAccount: authService.getAccount(),
    user: null,
    userPhoto: null,
    bu: "appBu"
};

export { IAccountState, initialState };
