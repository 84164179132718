import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import { authService } from "@/services";
import { settings } from "@/settings";

class ApiService {
    private readonly apiClient: AxiosInstance;

    private readonly graphClient: AxiosInstance;

    constructor() {
        this.apiClient = axios.create({
            baseURL: settings.webApi.baseUrl,
            responseType: "json",
        });

        this.graphClient = axios.create({
            baseURL: settings.graphApi.baseUrl,
            responseType: "json",
        });

        this.apiClient.interceptors.request.use(this.getApiRequestInterceptor);
        this.graphClient.interceptors.request.use(this.getGraphRequestInterceptor);
    }

    public getApiClient(): AxiosInstance {
        return this.apiClient;
    }

    public getGraphClient(): AxiosInstance {
        return this.graphClient;
    }

    private async getApiRequestInterceptor(request: AxiosRequestConfig) {
        const accessToken = await authService.getAccessToken(false);

        if (accessToken != null) {
            request.headers.Authorization = `Bearer ${accessToken}`;
        }

        return Promise.resolve(request);
    }

    private async getGraphRequestInterceptor(request: AxiosRequestConfig) {
        const accessToken = await authService.getGraphToken();
        if (accessToken != null) {
            request.headers.Authorization = `Bearer ${accessToken}`;
        }

        return Promise.resolve(request);
    }
}

const apiService = new ApiService();

export { apiService };
