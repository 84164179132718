const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const SET_GROUPS = "SET_GROUPS";
const SET_PHOTO = "SET_PHOTO";
const SET_USER = "SET_USER";

export {
    LOGIN,
    LOGOUT,
    SET_GROUPS,
    SET_PHOTO,
    SET_USER,
};
