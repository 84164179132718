
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Program extends Vue {
    public program: any[];

    constructor() {
        super();
        this.program = [
            {
                type: "dataBu",
                values: [
                    {
                        day: "Lundi",
                        items: [
                            {
                                isUrl: false,
                                url: "",
                                content:
                                    "Présentation RH, Installation matériel. data",
                            },
                            {
                                isUrl: false,
                                url: "",
                                content:
                                    "Pair Programming(Présentation de l'architecture de nos projets et bonnes pratiques) data",
                            },
                        ],
                    },
                    {
                        day: "Mardi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://app.pluralsight.com/library/courses/vue-getting-started/table-of-contents",
                                content: "Formation pluralsight (Vue JS) data",
                            },
                            {
                                isUrl: true,
                                url: "https://app.pluralsight.com/library/courses/vue-getting-started/table-of-contents",
                                content: "Formation pluralsight (Vue JS) data",
                            },
                        ],
                    },
                    {
                        day: "Mercredi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab data",
                            },
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab data",
                            },
                        ],
                    },
                    {
                        day: "Jeudi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab data",
                            },
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab data",
                            },
                        ],
                    },
                    {
                        day: "Vendredi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://www.pluralsight.com/courses/string-manipulation-c-sharp-best-practices",
                                content: "String Manipulation c# data",
                            },
                            {
                                isUrl: true,
                                url: "https://www.pluralsight.com/courses/c-sharp-language-integrated-query-linq",
                                content:
                                    "C# Language-Integrated Query (LINQ) data",
                            },
                        ],
                    },
                ],
            },
            {
                type: "appBu",
                values: [
                    {
                        day: "Lundi",
                        items: [
                            {
                                isUrl: false,
                                url: "",
                                content:
                                    "Présentation RH, Installation matériel.",
                            },
                            {
                                isUrl: false,
                                url: "",
                                content:
                                    "Pair Programming(Présentation de l'architecture de nos projets et bonnes pratiques)",
                            },
                        ],
                    },
                    {
                        day: "Mardi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://app.pluralsight.com/library/courses/vue-getting-started/table-of-contents",
                                content: "Formation pluralsight (Vue JS)",
                            },
                            {
                                isUrl: true,
                                url: "https://app.pluralsight.com/library/courses/vue-getting-started/table-of-contents",
                                content: "Formation pluralsight (Vue JS)",
                            },
                        ],
                    },
                    {
                        day: "Mercredi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                        ],
                    },
                    {
                        day: "Jeudi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                        ],
                    },
                    {
                        day: "Vendredi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://www.pluralsight.com/courses/string-manipulation-c-sharp-best-practices",
                                content: "String Manipulation c#",
                            },
                            {
                                isUrl: true,
                                url: "https://www.pluralsight.com/courses/c-sharp-language-integrated-query-linq",
                                content: "C# Language-Integrated Query (LINQ)",
                            },
                        ],
                    },
                ],
            },
            {
                type: "modernWkPlBu",
                values: [
                    {
                        day: "Lundi",
                        items: [
                            {
                                isUrl: false,
                                url: "",
                                content:
                                    "Présentation RH, Installation matériel. Modern",
                            },
                            {
                                isUrl: false,
                                url: "",
                                content:
                                    "Pair Programming(Présentation de l'architecture de nos projets et bonnes pratiques)",
                            },
                        ],
                    },
                    {
                        day: "Mardi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://app.pluralsight.com/library/courses/vue-getting-started/table-of-contents",
                                content: "Formation pluralsight (Vue JS)",
                            },
                            {
                                isUrl: true,
                                url: "https://app.pluralsight.com/library/courses/vue-getting-started/table-of-contents",
                                content: "Formation pluralsight (Vue JS)",
                            },
                        ],
                    },
                    {
                        day: "Mercredi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                        ],
                    },
                    {
                        day: "Jeudi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                        ],
                    },
                    {
                        day: "Vendredi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://www.pluralsight.com/courses/string-manipulation-c-sharp-best-practices",
                                content: "String Manipulation c#",
                            },
                            {
                                isUrl: true,
                                url: "https://www.pluralsight.com/courses/c-sharp-language-integrated-query-linq",
                                content: "C# Language-Integrated Query (LINQ)",
                            },
                        ],
                    },
                ],
            },
            {
                type: "lowCodeBu",
                values: [
                    {
                        day: "Lundi",
                        items: [
                            {
                                isUrl: false,
                                url: "",
                                content:
                                    "Présentation RH, Installation matériel. LowCode",
                            },
                            {
                                isUrl: false,
                                url: "",
                                content:
                                    "Pair Programming(Présentation de l'architecture de nos projets et bonnes pratiques)",
                            },
                        ],
                    },
                    {
                        day: "Mardi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://app.pluralsight.com/library/courses/vue-getting-started/table-of-contents",
                                content: "Formation pluralsight (Vue JS)",
                            },
                            {
                                isUrl: true,
                                url: "https://app.pluralsight.com/library/courses/vue-getting-started/table-of-contents",
                                content: "Formation pluralsight (Vue JS)",
                            },
                        ],
                    },
                    {
                        day: "Mercredi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                        ],
                    },
                    {
                        day: "Jeudi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                        ],
                    },
                    {
                        day: "Vendredi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://www.pluralsight.com/courses/string-manipulation-c-sharp-best-practices",
                                content: "String Manipulation c#",
                            },
                            {
                                isUrl: true,
                                url: "https://www.pluralsight.com/courses/c-sharp-language-integrated-query-linq",
                                content: "C# Language-Integrated Query (LINQ)",
                            },
                        ],
                    },
                ],
            },
            {
                type: "openBu",
                values: [
                    {
                        day: "Lundi",
                        items: [
                            {
                                isUrl: false,
                                url: "",
                                content:
                                    "Présentation RH, Installation matériel. LowCode",
                            },
                            {
                                isUrl: false,
                                url: "",
                                content:
                                    "Pair Programming(Présentation de l'architecture de nos projets et bonnes pratiques)",
                            },
                        ],
                    },
                    {
                        day: "Mardi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://app.pluralsight.com/library/courses/vue-getting-started/table-of-contents",
                                content: "Formation pluralsight (Vue JS)",
                            },
                            {
                                isUrl: true,
                                url: "https://app.pluralsight.com/library/courses/vue-getting-started/table-of-contents",
                                content: "Formation pluralsight (Vue JS)",
                            },
                        ],
                    },
                    {
                        day: "Mercredi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                        ],
                    },
                    {
                        day: "Jeudi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                            {
                                isUrl: true,
                                url: "https://github.com/microsoft/MCW-App-modernization/blob/main/Hands-on%20lab/HOL%20step-by-step%20-%20App%20modernization.md",
                                content: ".Net Modernization Lab",
                            },
                        ],
                    },
                    {
                        day: "Vendredi",
                        items: [
                            {
                                isUrl: true,
                                url: "https://www.pluralsight.com/courses/string-manipulation-c-sharp-best-practices",
                                content: "String Manipulation c#",
                            },
                            {
                                isUrl: true,
                                url: "https://www.pluralsight.com/courses/c-sharp-language-integrated-query-linq",
                                content: "C# Language-Integrated Query (LINQ)",
                            },
                        ],
                    },
                ],
            },
        ];
    }

    get getBu(): string {
        return this.$store.state.account.bu;
    }

    get getData(): any {
        return this.program.filter((x) => x.type === this.getBu)[0].values;
    }
}
