
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
@Component
export default class FileDrop extends Vue {

    @Prop()
    public validationErrors: string[]

    // internal properties
    private dragover: boolean;

    private uploadedFile: File;

    constructor() {
        super();

        this.dragover = false;
        this.uploadedFile = null;
    }

    mounted() {
        const dropzone = this.$el.firstChild;
        const fileupload = this.$refs.inputFile as HTMLInputElement;

        if (dropzone) {
            // register click listner
            dropzone.addEventListener("click", e => {
                e.preventDefault();
                if (fileupload) {
                    fileupload.click();
                }
            });
            // register all drag & drop event listeners
            dropzone.addEventListener("dragenter", e => {
                e.preventDefault();
                this.dragover = true;
            });
            dropzone.addEventListener("dragleave", e => {
                e.preventDefault();
                this.dragover = false;
            });
            dropzone.addEventListener("dragover", e => {
                e.preventDefault();
                this.dragover = true;
            });
            dropzone.addEventListener("drop", e => {
                e.preventDefault();
                const dragevent = e as DragEvent;
                if (dragevent.dataTransfer) {
                    this.uploadedFile = dragevent.dataTransfer.files[0];
                    this.filesSelected(this.uploadedFile);
                }
            });
        }
    }

    @Emit()
    filesSelected(file: File) {
        this.dragover = false;
    }

    public handleChange(file: Event) {
        this.uploadedFile = (file.target as HTMLInputElement).files[0];
        this.filesSelected(this.uploadedFile);
    }

    public get getFileName() {
        return this.uploadedFile?.name ?? "Aucun fichier n'a été chargé.";
    }

    public get getValidationErrors() {
        return this.validationErrors;
    }

}
