import { ArbreDecision } from "@/components";
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
    components: {
        ArbreDecision,
    },
    name: "what-to-do",
    metaInfo: {
        title: "Que faire ?",
    },
})
export default class WhatToDo extends Vue { }
