export const settings = {
    appName: "Strego",
    environment: process.env.VUE_APP_ENV as string,
    appInsights: {
        instrumentationKey: process.env.VUE_APP_APP_INSIGHTS_INSTRUMENTATION_KEY as string,
    },
    azureAd: {
        clientId: process.env.VUE_APP_AZURE_AD_CLIENTID as string,
        tenantId: process.env.VUE_APP_AZURE_AD_TENANTID as string,
        instance: process.env.VUE_APP_AZURE_AD_INSTANCE as string,
        callbackPath: process.env.VUE_APP_AZURE_AD_CALLBACKPATH as string,
        scopes: process.env.VUE_APP_AZURE_AD_SCOPES as string,
    },
    graphApi: {
        baseUrl: process.env.VUE_APP_GRAPH_API_BASE_URL as string,
        scopes: process.env.VUE_APP_GRAPH_SCOPES as string,
    },
    webApi: {
        baseUrl: process.env.VUE_APP_WEB_API_BASE_URL as string,
        delegationPath : process.env.VUE_APP_WEB_API_DELEGATION_PATH as string,
        dependencyPath : process.env.VUE_APP_WEB_API_DEPENDENCY_PATH as string,
    },
};
