import { Program } from "@/components";
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
    components: {
        Program,
    },
    name: "formation",
    metaInfo: {
        title: "Formation",
    },
})
export default class Formation extends Vue { }
