import { Vue, Component } from "vue-property-decorator";
import { Export, NavBar, Firework, ToggleButton } from "@/components"; // @ is an alias to /src

// import Markdown from "../../assets/guide_collab.md";
import HeaderCommun from "../../assets/markdown/shared/header_commun_guide_collab.md";
import FooterCommun from "../../assets/markdown/shared/footer_commun_guide_collab.md";
import AccesCommun from "../../assets/markdown/shared/acces_commun_guide_collab.md";
import AppBUBody from "../../assets/markdown/appBU/appBu_body_guide_collab.md";
import AppBUContacts from "../../assets/markdown/appBU/appBu_contacts_guide_collab.md";
import DataBUContacts from "../../assets/markdown/dataBU/dataBu_contacts_guide_collab.md";
import LowCodeBUContacts from "../../assets/markdown/lowCodeBU/lowCodeBu_contacts_guide_collab.md";
import OpenBUContacts from "../../assets/markdown/openBU/openBu_contacts_guide_collab.md";
import ModernWkPlBUContacts from "../../assets/markdown/modernWkPlBU/modernWkPlBu_contacts_guide_collab.md";
import DataBUBody from "../../assets/markdown/dataBU/dataBu_body_guide_collab.md";
import LowCodeBUBody from "../../assets/markdown/lowCodeBU/lowCodeBu_body_guide_collab.md";
import OpenBUBody from "../../assets/markdown/openBU/openBu_body_guide_collab.md";
import ModernWkPlBUBody from "../../assets/markdown/modernWkPlBU/modernWkPlBu_body_guide_collab.md";
import DataBUOrga from "../../assets/markdown/dataBU/dataBu_orga_guide_collab.md";
import AppBUOrga from "../../assets/markdown/appBU/appBu_orga_guide_collab.md";
import LowCodeBUOrga from "../../assets/markdown/lowCodeBU/lowCodeBu_orga_guide_collab.md";
import OpenBUOrga from "../../assets/markdown/openBU/openBu_orga_guide_collab.md";
import ModernWkPlBUOrga from "../../assets/markdown/modernWkPlBU/modernWkPlBu_orga_guide_collab.md";

@Component({
    components: {
        Export,
        NavBar,
        HeaderCommun,
        AccesCommun,
        AppBUBody,
        DataBUBody,
        LowCodeBUBody,
        ModernWkPlBUBody,
        DataBUOrga,
        AppBUOrga,
        LowCodeBUOrga,
        ModernWkPlBUOrga,
        FooterCommun,
        Firework,
        ToggleButton,
        AppBUContacts,
        DataBUContacts,
        LowCodeBUContacts,
        ModernWkPlBUContacts,
        OpenBUContacts,
        OpenBUBody,
        OpenBUOrga,
    },
    metaInfo: {
        title: "Noob'ees On Boarding",
    },
})
export default class Home extends Vue {
    get getBu(): string {
        return this.$store.state.account.bu;
    }
}
