import Vue from "vue";
import VueWait from "vue-wait";
import VueMeta from "vue-meta";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/notification";
import App from "./App.vue";
// Import hooks for vue-class-component
import "vue-class-component/hooks";

// Import global styles
import "@/assets/styles/global.scss";

Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV !== "production";


Vue.use(VueMeta);
Vue.use(VueWait);

new Vue({
    router,
    store,
    vuetify,
    wait: new VueWait({ useVuex: true }),
    render: h => h(App),
}).$mount("#app");
