const AppBU = "appBu";
const DataBU = "dataBu";
const ModernWkPlBU = "modernWkPlBu";
const LowCodeBU = "lowCodeBu";
const OpenBU = "openBu";

export default {
    AppBU,
    DataBU,
    ModernWkPlBU,
    LowCodeBU,
    OpenBU,
};
