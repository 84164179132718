
import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class Firework extends Vue {
    // @Prop()
    // public boxHeight: string;

    // @Prop()
    // public boxWidth: string;

    $refs!: {
        canvas: HTMLCanvasElement;
    };

    public width: number;

    public height: number;

    public seedAmount: number;

    public seeds: any[];

    public particles: any[];

    public auto: boolean;

    public elementVisible: boolean;

    public;

    constructor() {
        super();
        this.width = window.innerWidth;
        this.height = 490;
        this.seedAmount = 0;
        this.seeds = [];
        this.particles = [];
        this.auto = true;
        this.elementVisible = true;
    }

    public created(): void {
        setTimeout(() => (this.elementVisible = false), 10000);
    }

    public mounted() {
        const self = this;
        self.init();
        self.loop();
        window.addEventListener("click", (event) => {
            const seed = self.Seed(
                event.pageX,
                event.pageY,
                self.randomInt(175, 185),
                [self.randomInt(0, 359), "100%", "50%"]
            );
            self.seeds.push(seed);
        });
        window.addEventListener("resize", () => {
            self.width = window.innerWidth;
            self.height = window.innerHeight;
            this.$refs.canvas.width = self.width;
            self.clearCanvas();
        });
    }

    // public get canvas(): HTMLCanvasElement {
    //     return document.getElementById("canvas");
    // }
    public get boxHeight() {
        return window.innerHeight;
    }

    public get boxWidth() {
        return window.innerHeight;
    }

    public get ctx() {
        if (this.$refs.canvas !== undefined) {
            return this.$refs.canvas.getContext("2d");
        }

        return null;
    }

    public get canvasBoxHeight() {
        return this.boxHeight || "100%";
    }

    public get canvasBoxWidth() {
        return this.boxWidth || "100%";
    }

    public clearCanvas() {
        if (this.ctx !== undefined) {
            this.ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
            this.ctx.fillRect(0, 0, this.width, this.height);
        }
    }

    public circle(x, y, radius) {
        if (this.ctx !== undefined) {
            this.ctx.beginPath();
            this.ctx.arc(x, y, radius, 0, 2 * Math.PI);
            this.ctx.closePath();
        }
    }

    public loop() {
        if (this.ctx !== undefined) {
            this.clearCanvas();
            this.ctx.globalCompositeOperation = "lighter";

            for (let i = 0; i < this.seeds.length; i += 1) {
                if (!this.seeds[i].dead) {
                    this.seeds[i].move();
                    this.seeds[i].draw();
                } else {
                    this.seeds.splice(i, 1);
                }
            }

            for (let i = 0; i < this.particles.length; i += 1) {
                if (!this.particles[i].dead) {
                    this.particles[i].move();
                    this.particles[i].draw();
                } else {
                    this.particles.splice(i, 1);
                }
            }

            if (this.auto && this.seedAmount % 40 === 0) {
                const seed = this.Seed(
                    this.randomInt(20, this.width - 20),
                    this.height - 20,
                    this.randomInt(175, 185),
                    [this.randomInt(0, 359), "100%", "50%"]
                );
                this.seeds.push(seed);
            }

            this.ctx.globalCompositeOperation = "destination-out";
            requestAnimationFrame(this.loop);
            this.seedAmount += 1;
        }
    }

    public Seed(x, y, angle, color) {
        const self = this;
        const acceleration = 0.05;
        const radius = 3;
        const h = color[0];
        const s = color[1];
        const l = color[2];
        const finalColor = `hsla(${h}, ${s}, ${l}, 1)`;

        const dead = false;
        const fireSeed: any = {};
        let speed = 2;

        fireSeed.x = x;
        fireSeed.y = y;
        fireSeed.move = () => {
            if (fireSeed.y > self.randomInt(100, 200)) {
                speed += acceleration;
                fireSeed.x += speed * Math.sin((Math.PI / 180) * angle);
                fireSeed.y += speed * Math.cos((Math.PI / 180) * angle);
            } else if (!dead) {
                fireSeed.explode();
                fireSeed.dead = true;
            }
        };
        fireSeed.draw = () => {
            self.ctx.fillStyle = finalColor;
            self.circle(fireSeed.x, fireSeed.y, radius);
            self.ctx.fill();
        };
        fireSeed.explode = () => {
            for (let i = 0; i < 359; i += 4) {
                const particle = self.Firework(
                    fireSeed.x,
                    fireSeed.y,
                    i + self.randomInt(-200, 200) / 100,
                    [h, s, l]
                );
                self.particles.push(particle);
            }
        };
        fireSeed.dead = dead;
        return fireSeed;
    }

    public Firework(x, y, angle, color) {
        const self = this;
        const fireSeed: any = {};
        const angleOffset = self.randomInt(-20, 20) / 100;
        const radius = 1;
        const acceleration = -0.01;
        const gravity = 0.01;

        let opacity = 1;
        let finalColor = `hsla(${color[0]}, ${color[1]}, ${color[2]}, ${opacity})`;
        let verticalSpeed = 0;
        let speed = self.randomInt(195, 205) / 100;
        let targetAngle = angle;
        let positionX = x;
        let positionY = y;
        fireSeed.dead = false;
        fireSeed.move = () => {
            if (opacity > 0) {
                if (speed > 0) {
                    speed += acceleration;
                }

                targetAngle += angleOffset;
                opacity -= 0.005;
                finalColor = `hsla(${color[0]}, ${color[1]}, ${color[2]}, ${opacity})`;
                verticalSpeed += gravity;
                positionX += speed * Math.sin((Math.PI / 180) * targetAngle);
                positionY +=
                    speed * Math.cos((Math.PI / 180) * targetAngle) +
                    verticalSpeed;
            } else if (!fireSeed.dead) {
                fireSeed.dead = true;
            }
        };
        fireSeed.draw = () => {
            self.ctx.fillStyle = finalColor;
            self.circle(positionX, positionY, radius);
            self.ctx.fill();
        };

        return fireSeed;
    }

    public randomInt(min, max): number {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    public init(): void {
        this.$refs.canvas.width = this.width;
        this.$refs.canvas.height = this.height;
    }
}
